import * as Yup from 'yup';
import { FrontType } from '../../../../../@types/Kpi';
export function generateFieldValidation(kpi) {
    var schema = Yup.mixed();
    if (kpi === null || kpi === void 0 ? void 0 : kpi.isRequired) {
        schema = schema.required("".concat(kpi === null || kpi === void 0 ? void 0 : kpi.label, " is required"));
    }
    if ((kpi === null || kpi === void 0 ? void 0 : kpi.frontType) === FrontType.SELECT && (kpi === null || kpi === void 0 ? void 0 : kpi.choices)) {
        schema = schema.oneOf(kpi === null || kpi === void 0 ? void 0 : kpi.choices, "".concat(kpi === null || kpi === void 0 ? void 0 : kpi.label, " is required"));
    }
    return schema;
}
